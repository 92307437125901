<template>
  <div>
    <HeaderCustom></HeaderCustom>
    <div class="container" :class='$style.myContentsDiv'>
      <div class="row">
        <!--
        <div class="col-md-4 offset-md-4">
          <Search :searchList='this.data' @search='search' ref="txtSearch"></Search>
        </div> -->
        <div class="col-md-12">
          <h1 class="title menuTitle" style="font-weight: normal;" :class='$style.menuTitle'>{{title}}</h1>
        </div>
      </div>
      <div>
        <div class="row">
          <div class="col-lg-2 col-md-2 col-sm-12 mt-1">
            <button :disabled="this.selectedAssignments.length > 0 ? false : true" class="btn" :class="$style.otherButtons" :style='`background-color:${this.switchColor} !important`' @click="remove()"><i class="fa fa-trash"></i> Remove</button>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-12 mt-1">
            <button :disabled="this.selectedAssignments.length > 0 ? false : true" class="btn" :class="$style.otherButtons" data-toggle="modal" data-target="#editAllModal"><i class="fas fa-pencil-alt"></i> Edit </button>
          </div>
          <div class="col-lg-2 offset-lg-2 col-md-2 col-sm-12 mt-1 ">
            <!-- <button class="btn" :class="$style.createAssignmentBtn" @click="goTo('/assignment/report')"><i class="fa fa-table"></i> Report</button> -->
          </div>
          <div class="col-lg-2  col-md-3 col-sm-12 mt-1 ">
            <button class="btn" :class="$style.createAssignmentBtn" :style='`background-color:${this.switchColor} !important`' @click="goTo('/assignment/create')"><i class="fa fa-plus-circle"></i> Create</button>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-12 mt-1">
            <button class="btn" :class="$style.createAssignmentBtn" data-toggle="collapse" data-target="#collapseExample"><i class="fas fa-filter"></i> Filter</button>
          </div>
        </div>
        <div class="row collapse mt-3 mb-3 justify-content-md-center" style="width:100%" id="collapseExample">
          <div class="col-md-2 col-6 mt-4 text-center">
            <select class="form-control" name="" id="" @change="filterMeth()" v-model="selectedFilter">
              <option selected value="all">All</option>
              <option v-for="item in filterData" :key="item">{{ item }}</option>
            </select>
          </div>
          <div class="col-md-3 mt-1 text-center">
            <span>Start Date</span>
            <datetime type='date' v-model="startDate" :min-datetime='startDateMin' :max-datetime='startDateMax' :onchange="filterMeth()"></datetime>
          </div>
          <div class="col-md-3 mt-1 text-center">
            <span>End Date</span>
            <datetime type='date' v-model="dueDate" :min-datetime='startDateMin' :onchange="filterMeth()"></datetime>
          </div>
          <div class="col-md-1 mt-4">
            <button class="btn" :class="$style.createAssignmentBtn" title="Reset" @click="resetFilter()"><i class="fas fa-undo-alt"></i></button>
          </div>
        </div>
      <ul class="nav nav-tabs mt-5 row" :style='`border-color:${this.switchColor} !important`'>
        <li class="nav-item col" :style='`border-right-color:${this.switchColor} !important`'>
          <a class="nav-link" :style='`color:${this.switchColor} !important`' @click="changeData('individual')" style="cursor:pointer" ref="individual">Individual Assignments</a>
        </li>
        <li class="nav-item col" :style='`border-right-color:${this.switchColor} !important`'>
          <a class="nav-link active" :style='`background-color:${this.switchColor} !important`' @click="changeData('group')" style="cursor:pointer" ref="group">Group Assignments</a>
        </li>
        <!-- <li class="nav-item col">
          <a class="nav-link" @click="updateView('completed')" href="#" ref="completed">Completed Assignments</a>
        </li> -->
      </ul>
      <div v-if="!ready">
          <InnerLoading></InnerLoading>
      </div>
      <div v-else>
        <div v-if="this.activeDataSet.length > 0" :class="$style.columns" class="table-responsive">
          <table class="table table-hover table-bordered" :class="$style.customTable">
            <thead :class="$style.customThead" :style='`background-color:${this.switchColor} !important`'>
              <tr>
                <th>#</th>
                <th v-if="activeTab !== 'completed'">
                  <input type="checkbox" @click="cbClicked" class="check" id="allCheckBox">
                </th>
                <th>Assignment Name</th>
                <!-- <th>Lesson Name</th> -->
                <th>Assignee</th>
                <th>Start Date</th>
                <th>Due Date</th>
                <th>Details</th>
                <th>Edit</th>
                <!-- <th>Details</th> -->
              </tr>
            </thead>
            <tbody v-for="(item,i) in this.uniqData" :key="i">
              <tr class="table-light collapse-row collapsed" data-toggle="collapse" :data-target="`#accordion${i}`">
                <!-- <td>{{(currentPageIndex * dataCount) + i+1}}</td> -->
                <td>{{i+1}}</td>
                <td v-if="activeTab !== 'completed'">
                  <input type="checkbox" class="check" @click="cbClicked" :value="removeArray(item)">
                </td>
                <td>{{item.assignment_name}}</td>
                <!-- <td>{{item.scorm.scorm_name}}</td> -->
                <td>{{item.name}}</td>
                <td>{{ convertDate(item.start_date) }}</td>
                <td>{{ convertDate(item.end_date) }}</td>
                <td style="cursor:pointer">
                  <vue-custom-tooltip label="Assignment Details">
                  <i class="fas fa-eye"></i>
                  </vue-custom-tooltip>
                  <!-- <button class="btn" :class="$style.createAssignmentBtn"><i class="fa fa-info-circle"></i></button> -->
                </td>
                <td>
                  <vue-custom-tooltip label="Assignment Edit" position="is-left">
                  <a data-toggle="modal" data-target="#editModal" @click="editModal(item)"><i class="fas fa-pencil-alt"></i></a>
                  </vue-custom-tooltip>
                </td>
                <!-- <td>
                  <router-link 
                  :to="{ name: 'assignmentDetails', params: { id: item.assignment_id, community: item.assignment_type }}"
                  >
                    <button class="btn" :class="$style.btnDetails" title="Details"> 
                    <i class="fa fa-info-circle fa-1x"></i>
                    </button>
                  </router-link>
                </td> -->
              </tr>
              <tr>
                <td colspan="7">
                  <div :id="`accordion${i}`" class="collapse d-flex flex-wrap">
                    <template v-for="(child, j) in collapseOpenClose(item)">
                      <div class="col-md-3 col-6 mb-3" :key="j">
                        <Card  :key="j" contentType='details' :assignmentId='child.assignment_id' :communityType='child.assignment_type' :id="child.scorm.scorm_id" :title="child.scorm.scorm_name" :imgpath='child.scorm.scorm_image ? child.scorm.scorm_image : "https://storage.googleapis.com/ueslmscom/uploads/collection/1560865423.jpg"' :linkUrl="child.scorm.scorm_code" :collPath='child.scorm.collection_path'></Card>
                      </div>
                    </template>
                    <!-- <p >Lesson Name : {{child.scorm.scorm_name}}</p> -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="modal fade" id="editAllModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Update Assignments</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  
                  <div class="modal-body">
                    <div class="row">
                         <div class="col-md-6 mt-1 text-center">
                          <span>Due Date</span>
                          <datetime type='date' :onchange="filterEdit()" v-model="allEdit.end_date"></datetime>
                        </div>
                    </div>
                    <!-- <SecondStep 
                      :resources='this.assignmentData.assignment_scorms ? this.globalSelectedLessons : []'
                    ></SecondStep> -->
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="editAll">Update</button>
                    <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                  </div>
                </div>
              </div>
            </div>

            <div class="modal fade" id="editModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" style="max-width:90%;">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Update Assessment: {{selectEdit.name}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                         <div class="col-md-6 mt-1 mb-1 text-center">
                           <span>Assignment Name</span><br>
                          <input type="text" :class="$style.editInput" width="20" v-model="selectEdit.name">
                         </div> 
                         <div class="col-md-6 mt-1 mb-1 text-center">
                          <span>Due Date</span>
                          <datetime type='date' name='editDueDate' width="300px" :min-datetime='selectEdit.start_date' :onchange="filterEdit()" v-model="selectEdit.end_date"></datetime>
                        </div>
                    </div>
                    <div class="row">
                    <ScormAdd 
                      :resources='this.assignmentData.assignment_scorms ? this.globalSelectedLessons : []'
                      @selectedLessons="onClickChild"
                    ></ScormAdd>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="editUpdate">Update</button>
                    <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                  </div>
                </div>
              </div>
            </div>
          <!-- <div class="col-md-2 offset-md-10" :class="$style.divPagination">
            <a v-if="(totalIndividualAssignmentCount/dataCount) > currentPageIndex+1" href='#' class="btn pull-right" :class="$style.btnPage"
              @click="incCurrentPageIndex">
              <i class="fa fa-arrow-right"></i>
            </a>
            <a v-if="this.currentPageIndex !== 0" href='#' class="btn pull-right" :class="$style.btnPage" @click="decCurrentPageIndex">
              <i class="fa fa-arrow-left"></i>
            </a>
          </div> -->
        <div class="col-md-12" >
          <lmsPagination
            v-if="this.pagination && this.activeDataSet.length > 0"
            :data="this.pagination"
            @goToPage="goToPage">
          </lmsPagination>
        </div>
        </div>
        <div v-else>
          <p :class="$style.noContent">There is no Assignment.</p>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
  /*eslint-disable*/
  import _ from 'lodash';
  import swal from 'sweetalert';
  import tippy from 'tippy.js';

  import { mapActions, mapState, mapGetters } from 'vuex';
  import HeaderCustom from '@/components/HeaderCustom';
  import Search from '@/components/Search';
  import InnerLoading from '@/components/InnerLoading';
  import lmsPagination from '@/components/pagination';
  import Card from '@/components/Card2';
  import ScormAdd from '@/components/Assignments/Edit';
  import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip';

  

  import contentService from '@/services/content';

  export default {
    name: 'assignments',
    data() {
      return {
        data: [],
        activeTab: '',
        activeDataSet: [],
        title: '',
        contentType: '',
        selectedAssignments: [],
        searchText: '',
        tabData: ['individual', 'group'],
        ready: false,
        pagination: null,
        assignments2: null,
        classes: null,
        selectedFilter: 'all',
        filterData: [],
        startDateMin: null,
        startDateMax: null,
        dueDateMin: null,
        startDate: null,
        dueDate: null,
        selectEdit: {
          id: null,
          name: null,
          end_date: null,
          start_date: null,
          lessons: {},
        },
        allEdit: {
          end_date: null,
        },
        assignmentData: {},
        globalSelectedLessons: [],
      };
    },
    components: {
      HeaderCustom,
      Search,
      InnerLoading,
      lmsPagination,
      Card,
      ScormAdd,
      VueCustomTooltip,
    },
    computed: {
      ...mapState(['assignments', 'assignmentss']),
      ...mapGetters(['switchColor']),
    },
    watch: {
      assignments() {
        this.updateView();
      },
    },
    updated() {
      // console.log('startDate', this.startDate);
    },
    props:['selectedLessons'],
    // async beforeMount() {
    // },
    mounted() {
      tippy('[title]');
       if (this.$route.params.type === 'individual') {
        this.changeData('individual');
      } else {
        this.setData();
      }
    },
    methods: {
      ...mapActions(['getLmsAssignments', 'getCollections', 'getScorms', 'getStudentsUnselected']),
      updateView(tabParam) {
        if (!tabParam) {
          tabParam = 'group';
        }
        this.activeTab = tabParam;
        this.unselectAllSelectedLessons();
        this.setTabView();
        this.setTabData();
      },
      onClickChild (value) {
      // this.selectLessons = value.filter(x => x.assignment_name === item.assignment_name && x.name === item.name); // someValue
        this.globalSelectedLessons = value.map(x => x.id);
        
        
      },
      async editUpdate(){

       
        
        const data = {
          'assignment_name': this.selectEdit.name,
          'assignment_end_date': this.selectEdit.end_date,
        }
         this.selectEdit.lessons.forEach(async item => {
            await contentService.editAssignment(item.assignment_id, data);
         });
        
        let id = this.selectEdit.id;
        let scorms = this.globalSelectedLessons;
        if(scorms.length > 0){
          const res1 = await contentService.addScorms(id, scorms);
          swal('Assignments', 'Scorm Added successfully!', 'success').then(() => {
            });
        }
        
      
        swal('Assignments', 'Updated successfully!', 'success').then(() => {
          this.selectEdit = [];
          location.reload();
        });
        
      },
      async editAll(){
        // console.log(this.allEdit);
        const data = {
          'assignment_end_date': this.allEdit.end_date,
        }
        // console.log(data);
         this.selectedAssignments.forEach(async item => {
            const res = await contentService.editAssignmentDate(item, data);
            // console.log(res);
         });
      
          swal('Assignments', 'Updated successfully!', 'success').then(() => {
            this.selectEdit = [];
            location.reload();
          });
  
      },
      collapseOpenClose(item) {
        const den = this.activeDataSet.filter(x => x.assignment_name === item.assignment_name && x.name === item.name);
        return _.orderBy(den, ['scorm.scorm_name']);
      },
      editModal(item) {
        const den = this.activeDataSet.filter(x => x.assignment_name === item.assignment_name && x.name === item.name);
        this.selectEdit.lessons = den;
        this.selectEdit.name = item.name
        this.selectEdit.id = item.assignment_id;
        this.selectEdit.name = item.assignment_name;
        this.selectEdit.end_date = item.end_date;
        this.selectEdit.start_date = item.start_date;
        return true;
      },
      removeArray(item) {
        const data = this.activeDataSet.filter(x => x.assignment_name === item.assignment_name && x.name === item.name).map(x => x.assignment_id);
        return data;
      },
      async changeData(type) {
        this.$refs.group.setAttribute('style', 'background-color: white !important;');
        this.$refs.group.setAttribute('style', 'color: black !important;');
        this.$refs.individual.setAttribute('style', 'background-color: white !important;');
        this.$refs.individual.setAttribute('style', 'color: black !important;');
        if (type === 'group') {
          this.$refs.individual.classList = 'nav-link';
          this.$refs.group.classList = 'nav-link active';
          this.$refs.group.setAttribute('style', `background-color:${this.switchColor} !important`);

          this.setData();
        } else {
          this.ready = false;
          this.$refs.group.classList = 'nav-link';
          this.$refs.individual.classList = 'nav-link active';
          this.$refs.individual.setAttribute('style', `background-color:${this.switchColor} !important`);
          this.assignments2 = await contentService.getAssignments(1, 'id', 'individual');
          this.$store.commit('setIndAssignments', this.assignments2);
          this.pagination = this.assignments2.data.pagination;
          this.updateView('individual');
          this.ready = true;
        }
      },
      setTabView() {
        //* tabData yı kullanrak tüm tabları normale çek ardından aktif olanı active çek
        // this.tabData.forEach(element => {
        //   this.$refs[element].classList = 'nav-link';
        // });
        // this.$refs[this.activeTab].classList = 'nav-link active';
      },
      setTabData() {
        //* Datayı aktif olan taba göre şekillendir (eğer dolu ise searchText de dikkate alınır)
        this.activeDataSet = this.assignments2.data.data.filter(x => x.assignment_type === this.activeTab &&
        (x.assignment_name.toLocaleLowerCase('tr-TR').includes(this.searchText) ||
          x.scorm.scorm_name.toLocaleLowerCase('tr-TR').includes(this.searchText) ||
          x.name.toLocaleLowerCase('tr-TR').includes(this.searchText)));
        this.filterData = _.uniq(this.activeDataSet.map(x => x.name));
        this.uniqData = _.uniqBy(this.activeDataSet, v => [v.assignment_name, v.name].join());
        if (this.uniqData.length > 0) {
          this.startDateMin = _.orderBy(this.uniqData, 'start_date')[0].start_date.split(' ')[0];
          this.startDateMax = _.orderBy(this.uniqData, 'start_date', 'desc')[0].start_date.split(' ')[0];
          this.dueDateMin = _.orderBy(this.uniqData, 'end_date', 'desc')[0].end_date.split(' ')[0];
        }
      },
      unselectAllSelectedLessons() {
        this.selectedAssignments = [];
        const allChecks = document.getElementsByClassName('check');
        for (const item of allChecks) {
          item.checked = false;
        }
      },
      goTo(param) {
        this.$router.push(param);
      },
      convertDate(inputFormat) {
        function pad(s) {
          return (s < 10) ? `0${s}` : s;
        }
        const fixDateForAllBrowsers = inputFormat.replace(/-/g, '/');
        const d = new Date(fixDateForAllBrowsers);
        const first = [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-');
        // const second = [pad(d.getHours()), pad(d.getMinutes() + 1), d.getSeconds()].join(':');
        return `${first}`;
      },
      search(text) {
        if (!text) {
          this.searchText = '';
        } else {
          this.searchText = text.toLocaleLowerCase('tr-TR');
        }
        this.updateView(this.activeTab);
      },
      cbClicked(e) {
        if (e.target.id === 'allCheckBox') {
          //* Tüm assignmentlar tıklandı ise
          this.selectedAssignments = [];
          const allcb = document.getElementById('allCheckBox');
          const state = allcb.checked;
          const allChecks = document.getElementsByClassName('check');
          //* state true ise tüm checkboxlar seçili false ise tamamı boş olacak
          for (const item of allChecks) {
            item.checked = state;
            if (item.value !== 'on' && state) {
              this.selectedAssignments.push(...item.value.split(','));
            }
          }
          if (!state) {
            this.selectedAssignments = [];
          }
        } else {
          //* Tek bir assignment tıklandı ise
          if (e.target.checked) {
            //* Tek assignment seçiliş ise selectedAssignments arrayinde yoksa ekle ki seçili olmadığı durumda çıkarılacak yok olduğu farz edilebilir çünkü tik kaldırılınca diziden silinecek
            this.selectedAssignments.push(...e.target.value.split(','));
          } else if (!e.target.checked) {
            //* tiki kaldırılan checkboxı arrayden sil
            let checkAssignment = e.target.value.split(',');
            checkAssignment.forEach(element => {
                const i = this.selectedAssignments.indexOf(element);
                this.selectedAssignments.splice(i, 1);
            });
          }
        }
      },
      async remove() {
        this.ready = false;
        swal({
          title: 'Are you sure?',
          text: 'Once deleted, you will not be able to recover this assignments!',
          icon: 'warning',
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            contentService.deleteAssignments(this.selectedAssignments).then((res) => {
              if(!res.success) {
                this.ready = true;
                console.log('Assignment Delete Error', res.data);
                swal('','An error occured while you removing assignments', 'error');
              } else {
                swal('Assignments', 'Removed successfully!', 'success').then(() => {
                  this.ready = true;
                  this.selectedAssignments = [];
                  location.reload();
                });
              }
            });   
          } else {
            this.ready = true;
          }
        });
      },
      setData() {
        const querystring = this.$route.query;
        if (querystring.page) {
          this.getData(querystring.page);
        } else {
          this.goToPage(1);
        }
      },
      goToPage(page) {
        this.$router.push({
          name: 'teacherAssignments',
          params: {
          },
          query: { page },
        });
      },
      async getData(page) {
        this.ready = false;
        this.contentType = 'lesson';
        this.title = 'ASSIGNMENTS';
        // if (!this.assignmentss && page === 1) {
        this.assignments2 = await contentService.getAssignments(page, 'id');
        this.$store.commit('setAssignments', this.assignments2);
        this.pagination = this.assignments2.data.pagination;
        // await this.getCollections();
        // await this.getScorms();
        await this.getStudentsUnselected();
        this.updateView();
        // } else {
        //   this.assignments2 = this.assignmentss;
        // }
        this.ready = true;
      },
      resetFilter() {
        this.ready = false;
        this.selectedFilter = 'all';
        this.startDate = null;
        this.dueDate = null;
        this.uniqData = _.uniqBy(this.activeDataSet, 'assignment_name');
        this.ready = true;
      },
      filterMeth() {
        // this.uniqData = _.uniqBy(this.activeDataSet, 'assignment_name');
        this.uniqData = _.uniqBy(this.activeDataSet, v => [v.assignment_name, v.name].join());
        if (this.selectedFilter !== 'all') {
          this.uniqData = this.uniqData.filter(x => x.name === this.selectedFilter);
        }
        if (this.startDate) {
          this.uniqData = this.uniqData.filter(x => x.start_date.split(' ')[0] >= this.startDate.split('T')[0]);
        }
        if (this.dueDate) {
          this.uniqData = this.uniqData.filter(x => x.start_date.split(' ')[0] <= this.dueDate.split('T')[0]);
        }
        // const filtered = this.uniqData.filter(x =>
        //   this.selectedFilter !== 'all' && x.name === this.selectedFilter
        //   || this.startDate && x.start_date >= this.startDate
        //   || this.dueDate && x.end_date <= this.dueDate);
        // this.uniqData = filtered;
      },
      filterEdit(){
          if (this.selectEdit.end_date) {
            this.selectEdit.end_date = this.selectEdit.end_date.split('T')[0];
          }
          if (this.selectEdit.end_date) {
            this.selectEdit.end_date = this.selectEdit.end_date.split('T')[0];
          }
          if (this.allEdit.end_date) {
            this.allEdit.end_date = this.allEdit.end_date.split('T')[0];
          }
      },
      setAssignmentData(data) {
      /*eslint-disable*/
        for (const item of Object.keys(data)) {
          if (item === 'assignment_scorms') {
            this.globalSelectedLessons = data[item];
            this.assignmentData[item] = data[item].map(x => x.id);
          } else {
            this.assignmentData[item] = data[item];
          }
        }
      },
    },
    destroyed() {
      this.unselectAllSelectedLessons();
    },
    watch: {
      $route() {
        this.setData();
      },
    },
  };
</script>

<style src='./style.less' lang='less' module/>

<style scoped>
  .nav-tabs {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .collapse-row.collapsed + tr {
    display: none;
  }
</style>