<template>
  <div v-if="!isHome">
    <HeaderCustom></HeaderCustom>
    <div class="container" :class='$style.myContentsDiv'>
      <div class="row">
        <!-- <div class="col-md-3" v-if="scormTypes.length > 0 && scormLevels.length > 0 && scormCategories.length > 0">
          <div :class="$style.divDdl" :style='`background-color:${this.switchColor} !important`'>
            <select class="form-control" v-model="selectedType" v-html="typesOptions" :class="$style.combobox">
            </select>
          </div>
        </div>
        <div class="col-md-3" v-if="scormTypes.length > 0 && scormLevels.length > 0 && scormCategories.length > 0">
          <div :class="$style.divDdl" :style='`background-color:${this.switchColor} !important`'>
            <select class="form-control" v-model="selectedLevel" v-html="levelsOptions" :class="$style.combobox">
            </select>
          </div>
        </div>
        <div class="col-md-3" v-if="scormTypes.length > 0 && scormLevels.length > 0 && scormCategories.length > 0">
          <div :class="$style.divDdl" :style='`background-color:${this.switchColor} !important`'>
            <select class="form-control" v-model="selectedCategory" v-html="categoriesOptions" :class="$style.combobox">
            </select>
          </div>
        </div>
        <div v-if="scormTypes.length > 0 && scormLevels.length > 0 && scormCategories.length > 0" class="col-md-3">
          <div :class="$style.divDdl" :style='`background-color:${this.switchColor} !important`'>
            <Search :class="$style.divSearch"></Search>
          </div>
        </div> -->
        <div class="col-md-4 offset-md-4">
          <!-- <Search></Search> -->
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h1 class="title menuTitle" :class='$style.menuTitle'>{{title}}</h1>
        </div>
      </div>
      <div v-if="!ready">
          <InnerLoading></InnerLoading>
      </div>
      <!-- <div v-else-if="this.LessonsData.length > 0 || LessonsDataDetails" :class="$style.columns">
        <template v-if="viewState === 'parent' && LessonsData.length > 0">
        <div class="row" v-for="(parent,k) in LessonsData.filter(x => x.category_parent_id === 0)" :key="k">
          <div class="col-md-12">
            <h5>{{parent.category_name}}</h5>
            <hr>
          </div>
          <div class="col-md-4" @click="goToLessons(child)" :class="$style.column" 
          v-for="(child,i) in LessonsData.filter(x => x.category_parent_id === parent.category_id)" :key="i">
            <Card contentType='pdf' :id='child.category_id' :title='child.category_name' :imgpath='child.category_image'></Card>
          </div>
        </div>
        </template>
        <template v-if="LessonsDataDetails && viewState === 'child'">
          <hr>
          <div class="row">
            <div class="col-md-4" :class="$style.column" v-for="(parent) in LessonsDataDetails" :key="parent.pdf_id">
              <Card contentType='link' :linkUrl='parent.pdf_url' :id='parent.pdf_id' :title='parent.pdf_name' 
              :imgpath='parent.pdf_image ? parent.pdf_image : "https://cdn0.iconfinder.com/data/icons/seo-smart-pack/128/grey_new_seo-37-512.png"'
                 ></Card>
            </div>
          </div>
        </template>
      </div> -->
      <div v-else>
        <p :class="$style.noContent">There is no content here.</p>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  // import _ from 'lodash';
  import HeaderCustom from '@/components/HeaderCustom';
  import InnerLoading from '@/components/InnerLoading';
  import services from '@/services/content';

  // import Card from '@/components/Card';
  // import Search from '@/components/Search';

  export default {
    name: 'collectionLessons',
    data() {
      return {
        LessonsData: [],
        LessonsDataDetails: [],
        packageData: [],
        title: '',
        contentType: '',
        viewState: '',
        ready: false,
        collectionId: 0,
        scormTypes: [],
        scormLevels: [],
        scormCategories: [],
        selectedType: 'all',
        selectedLevel: 0,
        selectedCategory: 0,
        searchText: '',
        typesOptions: '',
        levelsOptions: '',
        categoriesOptions: '',
        readers: false,
      };
    },
    watch: {
      $route() {
        this.setPage();
      },
    },
    components: {
      HeaderCustom,
      // Card,
      // Search,
      InnerLoading,
    },
    props: ['isHome'],
    computed: {
      ...mapGetters(['switchColor']),
    },
    async beforeMount() {
      this.ready = false;
      this.title = 'LESSONS';
      this.setPage();
    },
    methods: {
      async setPage() {
        // if (!this.isHome) {
        //   this.fillDropdowns();
        // }
        const collectionsLessonsId = parseInt(this.$route.params.id, 10);
        if (collectionsLessonsId) {
          const stayData = {
            category_id: collectionsLessonsId,
            category_name: this.$route.params.title,
          };
          this.goToLessons(stayData);
        } else {
          this.title = 'LESSONS';
          const data = await services.getCollectionsLessons();
          this.LessonsData = data.data;
          this.viewState = 'parent';
          //! Eğer Collectionlardaki kırılım sayısı 2 ise direk scorms sayfasına gitmeli
          //! 3 ise colections sayfasına gitmeli
          this.contentType = 'collections';
          this.ready = true;
        }
      },
      async goToLessons(categoryData) {
        this.ready = false;
        const res = await services.getCollectionsLessonsDetails(categoryData.category_id);
        if (res.success) {
          this.routerObj = {
            name: 'collectionsLessons',
            params: {
              id: categoryData.category_id,
              title: categoryData.category_name,
            },
          };
          this.$router.push(this.routerObj);
          this.viewState = 'child';
          this.title = categoryData.category_name;
          this.LessonsDataDetails = res.data;
        } else {
          this.LessonsDataDetails = [];
        }
        this.ready = true;
      },
    },
  };
</script>

<style src='./style.less' lang='less' module/>
