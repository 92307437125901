<template>
  <div :class="$style.divLogin">
    <div :class="$style.loginform">
      <div>
        <div :class="$style.logo"> </div>
      </div>
      <div class="g-signin2" data-onsuccess="onSignIn" data-theme="dark" :class="$style.ssoBtn"></div>
    </div>
  </div>
</template>

<script>
  import swal from 'sweetalert2';
  // import userService from '@/services/user';
  // import organizationService from '@/services/organization';
  // import slack from '@/services/slack';
  import authService from '@/services/auth';
  import axios from 'axios';

  export default {
    name: 'login',
    data() {
      return {
        username: '',
        password: '',
        isChecked: false,
        ipAdress: '',
      };
    },
    methods: {
      getCredentials(profileData) {
        // const userId = profileData.getId();
        // const firstName = profileData.getGivenName();
        // const lastName = profileData.getFamilyName();
        // ! email olarak profileData.getEmail(); bilgisi kullanılmalı ve aşağıda gönderilmeli
        const email = profileData.getEmail();
        const obj = {
          username: email,
          password: email.split('@')[0],
        };
        return obj;
      },
    },
    beforeMount() {
      window.addEventListener('ssologin', async (e) => {
        const credentials = this.getCredentials(e.detail);
        // console.log('adı şifre', credentials);
        const res = await this.$store.dispatch('login', credentials);
        if (res.success) {
          const ipData = await axios.get("https://api.ipify.org?format=json")
          .catch(err => {
              console.log('err', err);
          });

          this.ipAdress = ipData ? ipData.data.ip : '127.0.0.1';

          const userAgent = navigator.userAgent;
          await authService.loginLog(userAgent, this.ipAdress).then(() => {
            this.$router.push('/home');
          }).catch(err => console.log('err', err));
          this.$router.push('/home');
        } else {
          swal('Invalid or incorrect e-mail address information.', 'Please verify your e-mail address in the online chat.', 'error');
        }

        // else {
        //   // ! Kullanıcı AGK öğrencisidir ya da öğretmeni
        //   // ! bir organizasyona post edilmeli
        //   const orgs = await organizationService.getPlatformOrganizations();
        //   const xGradeId = orgs.data.find(x => x.type === 'grade' && x.name === 'X').id;
        //   let gradeId = orgs.data.find(x => x.type === 'grade' && x.name === 'X').id;
        //   if (e.detail.role === 'teacher') {
        //     e.detail.organization_id = gradeId;
        //   } else {
        //     // Platform organizasyonlarını çek
        //     // Sınıf adından İD'yi yakala
        //     const grade = await organizationService.getGrade(e.detail.login_name);
        //     if (grade.success) {
        //       if (grade.data.Sinif !== 'Hata') {
        //         gradeId = orgs.data
        //  .find(x => x.type === 'grade' && x.name === grade.data.Sinif).id;
        //         if (!gradeId) {
        //           // ! Böyle bir organizasyon yok ekle
        //           const no = grade.data.Sinif.substring(0, 1);
        //           let level = null;
        //           if (Number(no) < 5) {
        //             level = orgs.data.find(x => x.type === 'level' && x.name === 'İlkokul');
        //           } else if (Number(no) < 9) {
        //             level = orgs.data.find(x => x.type === 'level' && x.name === 'Ortaokul');
        //           } else if (Number(no) >= 9) {
        //             level = orgs.data.find(x => x.type === 'level' && x.name === 'Lise');
        //           }
        //           if (level) {
        //             const gradeObj = {
        //               name: grade.data.Sinif,
        //               parent: level.id,
        //             };
        //             const newGrade = await organizationService.setOrganization(gradeObj);
        //             if (newGrade.success) {
        //               gradeId = newGrade.data.id;
        //             }
        //           }
        //         }
        //       }
        //     }
        //     e.detail.organization_id = gradeId;
        //   }
        //   if (!e.detail.first_name || !e.detail.last_name) {
        //     //! ALERT(İSİM-SOYİSİM KONTROL)
        //     swal('Hata', 'Lütfen Google hesabınızın İsim ve Soyisim
        //  bilgilerinin eksiksiz olduğundan emin olunuz.', 'error');
        //     return;
        //   }
        //   await userService.createUser(e.detail);
        //   res = await this.$store.dispatch('login', e.detail);
        //   if (res.success) {
        //     this.$router.push('/home');
        //     if (gradeId === xGradeId) {
        //       slack.sendMessage('X Sınıfına eklenen kullanıcı', [
        //         {
        //           title: 'Ad Soyad',
        //           value: `${e.detail.first_name} ${e.detail.last_name}`,
        //           short: false,
        //         },
        //         {
        //           title: 'Mail Adresi',
        //           value: e.detail.login_name,
        //           short: false,
        //         },
        //       ]);
        //     }
        //   }
        // }
      });
      localStorage.clear();
    },
  };

</script>

<style src='./style.less' lang='less' module/>

<style>
  .abcRioButton {
    width: 300px !important;
  }

</style>
