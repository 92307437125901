module.exports = {
  global_key: {
    tokenName: 'access_token',
  },
  colors: {
    primaryColor: '#6f2b8f',
    secondaryColor: '#d42e12',
    backgroundColor: 'whitesmoke',
  },
  apiKey: 'nNdM7MTz13y8049NSjnre3H3jUTbMyKRH444YQjC8LJmi1do',
};
