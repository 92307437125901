import Vue from 'vue';
import Router from 'vue-router';

import Home from '@/components/Home';
import Login from '@/components/Login';
import UesLogin from '@/components/UesLogin';
import StudentAssignments from '@/components/Assignments/Student';
import TeacherAssignments from '@/components/Assignments/Teacher';
import AssignmentDetails from '@/components/Assignments/Details';
import AssignmentCreate from '@/components/Assignments/Create';
import AssignmentReport from '@/components/Assignments/Report';
import Lessons from '@/components/Lessons';
import Scorm from '@/components/Scorm';
import Collections from '@/components/Collections';
import CollectionsLessons from '@/components/CollectionsLessons';
import TeacherResources from '@/components/TeacherResources';
import ELibrary from '@/components/ELibrary';

import Scorms from '@/components/Scorms';
import Library from '@/components/Library';

import tools from '@/tools';
import store from '../stores';


Vue.use(Router);

const router = new Router({
    routes: [
      {
        path: '/',
        name: 'login',
        component: Login,
      },
      {
        path: '/ues',
        name: 'ueslogin',
        component: UesLogin,
      },
      {
        path: '/home',
        name: 'home',
        component: Home,
      },
      {
        path: '/assignments',
        name: 'studentAssignments',
        component: StudentAssignments,
        beforeEnter: (to, from, next) => {
          if (store.state.roleType === 'teacher') {
            next({
              name: 'teacherAssignments',
            });
          }
          next();
        },
      },
      {
        path: '/assignments',
        name: 'teacherAssignments',
        component: TeacherAssignments,
      },
      {
        path: '/teacherResources',
        name: 'teachersResources',
        component: TeacherResources,
      },
      {
        path: '/elibrary',
        name: 'elibrary',
        component: ELibrary,
      },
      {
        path: '/collectionsLessons/:id?/:title?',
        name: 'collectionsLessons',
        component: CollectionsLessons,
      },
      {
        path: '/mycontents',
        name: 'mycontents',
        component: Collections,
      },
      {
        path: '/mycontents/:id',
        name: 'lessons',
        component: Lessons,
      },
      {
        path: '/collections/:id?/:searchList?',
        name: 'collections',
        component: Collections,
      },
      {
        path: '/scorms/:id',
        name: 'scorms',
        component: Scorms,
      },
      {
        path: '/library/:id?',
        name: 'library',
        component: Library,
      },
      {
        path: '/scorm/:id/:packageType?/:packageId?/:scormCode/:scormTitle/:scormDesc?',
        name: 'scorm',
        component: Scorm,
        props: true,
      },
      {
        path: '/assignment/details/:id/:community/:userId?',
        name: 'assignmentDetails',
        component: AssignmentDetails,
      },
      {
        path: '/assignment/create',
        name: 'assignmentCreate',
        component: AssignmentCreate,
      },
      {
        path: '/assignment/report',
        name: 'assignmentRepor',
        component: AssignmentReport,
      },
    ],
    // mode: 'history'
});

export default router;


// const originalPush = Router.prototype.push;
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(() => {
//   });
// };

router.afterEach((to) => {
  if (to.name !== 'login' && to.name !== 'ueslogin') {
    if (!tools.cookie.get('access_token')) {
      router.push({ name: 'login' });
    }
  }
  if (to.name === 'login') {
    if (tools.cookie.get('access_token')) {
      router.push({ path: '/' });
    }
  }
});
