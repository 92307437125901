import _ from 'lodash';
import userService from '../services/user';

export default {
  state: {
    userDetails: null,
    grade: 0,
    roleType: '',
    students: [],
    internet: {
      success: false,
      speed: 0,
    },
  },
  getters: {
    switchColor: (state) => {
      let color = '#f5f5f5';
      if (!state.userDetails) return color;
      const lel = state.userDetails.organization.find(x => x.type === 'level').name;
      switch (lel) {
        case 'Ortaokul':
          color = '#E2251B';
          break;
        case 'İlkokul':
          color = '#00B2E2';
          break;
        case 'Lise':
          color = '#009482';
          break;
        default:
          color = '#A777A6';
      }
      return color;
    },
    userDetails: state => state.userDetails,
    profileData: (state) => {
      if (state.userDetails) {
        if (state.userDetails.role_type === 'student') {
          const newObj = _.cloneDeep(state.userDetails);
          return {
            avatar: newObj.avatar,
            username: newObj.email,
            first_name: newObj.first_name,
            last_name: newObj.last_name,
            schoolName: _.find(newObj.organization, org => org.type === 'main').name,
            branchName: _.find(newObj.organization, org => org.type === 'campus').name,
            gradeName: _.find(newObj.organization, org => org.type === 'grade').name,
          };
        } else if (state.userDetails.role_type === 'teacher') {
          const newObj = _.cloneDeep(state.userDetails);
          return {
            avatar: newObj.avatar,
            username: newObj.email,
            first_name: newObj.first_name,
            last_name: newObj.last_name,
            schoolName: _.find(newObj.organization, org => org.type === 'main').name,
            branchName: _.find(newObj.organization, org => org.type === 'campus').name,
            gradeName: _.filter(newObj.organization, org => org.type === 'grade').map(x => x.name).join(' / '),
          };
        }
      }
      return {};
    },
  },
  mutations: {
    setUserDetails(state, payload) {
      state.userDetails = payload;
      state.roleType = payload.role_type;
      if (payload.role_type === 'student') {
        state.grade = payload.organization.find(org => org.type === 'grade').name.split('-')[0];
      }
    },
    async setTeacherStudents(state, payload) {
      const res = await userService.getTeacherStudents(payload.organization);
      state.students = res;
    },
    async setStudentsUnselected(state) {
      state.students.forEach((grade) => {
        grade.selected = false;
        grade.opened = false;
        grade.children.forEach((stu) => {
          stu.selected = false;
          stu.opened = false;
        });
      });
    },
    async setInternet(state, payload) {
      state.internet = payload;
    },
  },
  actions: {
    async getUserDetails(context) {
      const res = await userService.getStudentDetails();
      context.commit('setUserDetails', res.data);
      if (res.data.role_type === 'teacher') {
        context.commit('setTeacherStudents', res.data);
      }
      // if (context.state.roleType === 'student') {
      //   context.dispatch('getGoogleClassroomData');
      // }
    },
    getStudentsUnselected(context) {
      context.commit('setStudentsUnselected');
    },
    getInternet(context, payload) {
      context.commit('setInternet', payload);
    },
  },
};
