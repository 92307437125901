<template>
  <div v-if="!isHome">
    <HeaderCustom></HeaderCustom>
    <div class="container" :class='$style.myContentsDiv'>
      <div class="row">
        <!-- <div class="col-md-3" v-if="scormTypes.length > 0 && scormLevels.length > 0 && scormCategories.length > 0">
          <div :class="$style.divDdl" :style='`background-color:${this.switchColor} !important`'>
            <select class="form-control" v-model="selectedType" v-html="typesOptions" :class="$style.combobox">
            </select>
          </div>
        </div>
        <div class="col-md-3" v-if="scormTypes.length > 0 && scormLevels.length > 0 && scormCategories.length > 0">
          <div :class="$style.divDdl" :style='`background-color:${this.switchColor} !important`'>
            <select class="form-control" v-model="selectedLevel" v-html="levelsOptions" :class="$style.combobox">
            </select>
          </div>
        </div>
        <div class="col-md-3" v-if="scormTypes.length > 0 && scormLevels.length > 0 && scormCategories.length > 0">
          <div :class="$style.divDdl" :style='`background-color:${this.switchColor} !important`'>
            <select class="form-control" v-model="selectedCategory" v-html="categoriesOptions" :class="$style.combobox">
            </select>
          </div>
        </div>
        <div v-if="scormTypes.length > 0 && scormLevels.length > 0 && scormCategories.length > 0" class="col-md-3">
          <div :class="$style.divDdl" :style='`background-color:${this.switchColor} !important`'>
            <Search :class="$style.divSearch"></Search>
          </div>
        </div> -->
        <div class="col-md-4 offset-md-4">
          <!-- <Search></Search> -->
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h1 class="title menuTitle" :class='$style.menuTitle'>{{title}}</h1>
        </div>
      </div>
      <div v-if="!ready">
          <InnerLoading></InnerLoading>
      </div>
      <div v-else :class="$style.column" class="row">
        <div class="col-md-12">
          <hr>
        </div>
        <div class="col-md-4 mt-1" :class="$style.column">
          <Card contentType='link' linkUrl='https://mail.google.com/' :id='2' title='GMAIL' 
            :imgpath='"../../../static/img/platform/gmail.png"'
          ></Card>
        </div>
        <div class="col-md-4 mt-1" :class="$style.column">
          <Card contentType='link' linkUrl='https://drive.google.com/' :id='3' title='DRIVE' 
            :imgpath='"../../../static/img/platform/gdrive.png"'
          ></Card>
        </div>
        <div class="col-md-4 mt-1" :class="$style.column">
          <Card contentType='link' linkUrl='https://calendar.google.com/' :id='4' title='CALENDAR' 
            :imgpath='"../../../static/img/platform/gcalendar.png"'
          ></Card>
        </div>
        <div class="col-md-4 mt-1" :class="$style.column">
          <Card contentType='link' linkUrl='https://agk.k12net.com/Login.aspx' :id='5' title='K12NET' 
            :imgpath='"../../../static/img/platform/k12net.png"'
          ></Card>
        </div>
        <div class="col-md-4 mt-1" :class="$style.column">
          <Card contentType='link' linkUrl='https://issuu.com/gundogdueyayin/stacks' :id='1' title='E-Library' 
            :imgpath='"https://photo.isu.pub/gundogdueyayin/photo_large.jpg"'
          ></Card>
        </div>
      </div>
      <!-- <div v-else>
        <p :class="$style.noContent">There is no content here.</p>
      </div> -->
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  // import _ from 'lodash';
  import HeaderCustom from '@/components/HeaderCustom';
  import InnerLoading from '@/components/InnerLoading';
  import services from '@/services/content';

  import Card from '@/components/Card';
  // import Search from '@/components/Search';

  export default {
    name: 'ELibrary',
    data() {
      return {
        LessonsData: [],
        LessonsDataDetails: [],
        packageData: [],
        title: '',
        contentType: '',
        viewState: '',
        ready: false,
        collectionId: 0,
        scormTypes: [],
        scormLevels: [],
        scormCategories: [],
        selectedType: 'all',
        selectedLevel: 0,
        selectedCategory: 0,
        searchText: '',
        typesOptions: '',
        levelsOptions: '',
        categoriesOptions: '',
        readers: false,
      };
    },
    watch: {
      $route() {
        this.setPage();
      },
    },
    components: {
      HeaderCustom,
      Card,
      // Search,
      InnerLoading,
    },
    props: ['isHome'],
    computed: {
      ...mapGetters(['switchColor']),
    },
    async beforeMount() {
      this.ready = false;
      this.title = 'E-LIBRARY';
      this.setPage();
    },
    methods: {
      async setPage() {
        // if (!this.isHome) {
        //   this.fillDropdowns();
        // }
        const collectionsLessonsId = parseInt(this.$route.params.id, 10);
        if (collectionsLessonsId) {
          const stayData = {
            category_id: collectionsLessonsId,
            category_name: this.$route.params.title,
          };
          this.goToLessons(stayData);
        } else {
          this.title = 'E-LIBRARY';
          const data = await services.getCollectionsLessons();
          this.LessonsData = data.data;
          this.viewState = 'parent';
          //! Eğer Collectionlardaki kırılım sayısı 2 ise direk scorms sayfasına gitmeli
          //! 3 ise colections sayfasına gitmeli
          this.contentType = 'collections';
          this.ready = true;
        }
      },
      async goToLessons(categoryData) {
        this.ready = false;
        const res = await services.getCollectionsLessonsDetails(categoryData.category_id);
        if (res.success) {
          this.routerObj = {
            name: 'collectionsLessons',
            params: {
              id: categoryData.category_id,
              title: categoryData.category_name,
            },
          };
          this.$router.push(this.routerObj);
          this.viewState = 'child';
          this.title = categoryData.category_name;
          this.LessonsDataDetails = res.data;
        } else {
          this.LessonsDataDetails = [];
        }
        this.ready = true;
      },
    },
  };
</script>

<style src='./style.less' lang='less' module/>
